import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import App from './App';

import store from 'store';

import reportWebVitals from './reportWebVitals';

import './index.scss';

import Bugsnag from '@bugsnag/js';
import crashLogger from 'utils/logger';

const ErrorBoundary = crashLogger.bugsnagEnabled
	? Bugsnag.getPlugin('react').createErrorBoundary(React)
	: React.Fragment;

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Provider store={store}>
				<App />
			</Provider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
