import React, {useState, useEffect} from 'react';

import styles from './index.module.scss';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useDispatch, useSelector} from 'react-redux';
import {removeToast} from 'store/actions/toast';

const Toast = () => {
	const dispatch = useDispatch();
	let autoHideToasterCountdown;

	const autoHideTimer = 5000;
	const renderDelay = 400;

	const toastData = useSelector(state => state.toast);

	const [mountToaster, setMountToaster] = useState(false);
	const [showContent, setShowContent] = useState(false);
	const [toastContent, setToastContent] = useState({});

	const dismissToaster = () => dispatch(removeToast());

	const openToaster = async () => {
		setToastContent(toastData);
		setMountToaster(true); // mount toaster component
		setTimeout(() => {
			setShowContent(true); // show toaster
			autoHideToasterCountdown = setTimeout(dismissToaster, autoHideTimer);
		}, renderDelay);
	};

	const closeToaster = async () => {
		if (mountToaster) {
			setShowContent(false); // hide toaster
			setTimeout(() => {
				setMountToaster(false);
			}, renderDelay); // unmount toaster component after animation finished
		}
	};

	useEffect(() => {
		if (toastData.show && mountToaster) {
			/**
			 * check if toast is currently mounted
			 * if yes then force unmount and re-mount toast with new content
			 */
			closeToaster();
			setTimeout(() => {
				openToaster();
			}, renderDelay);
		} else {
			toastData.show ? openToaster() : closeToaster();
		}
		return () => {
			clearTimeout(autoHideToasterCountdown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toastData]);

	return (
		mountToaster && (
			<div className={clsx(
				styles.Toast,
				showContent && styles.Toast__visible,
				toastContent.type === 'alert' && styles['Toast--alert'],
				toastContent.type === 'error' && styles['Toast--error'],
			)}>
				<div className={styles.Toast__bar}>
					{
						toastContent.icon && (
							<div className={styles.Toast__icon__wrapper}>
								<FontAwesomeIcon
									icon={toastContent.icon}
									className={styles.Toast__icon} />
							</div>
						)
					}
					<div className={clsx(
						styles.Toast__message,
						!toastContent.icon && styles['Toast__message--align-center'],
					)}>
						{toastContent.message}
					</div>
				</div>
			</div>
		)
	);
};

export default Toast;