import axios from 'axios';
import crashLogger from 'utils/logger';
import {getOutletCode} from 'utils/utils';

const baseURL = process.env.REACT_APP_HS_BASE_URL;

export const startShift = async () => {
	try {
		const outletCode = getOutletCode();
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/shift/start`,
			data: {
				outletCode,
			},
		});
		return data?.success;
	} catch (e) {
		crashLogger.error(e);
	}
};

export const endShift = async () => {
	try {
		const outletCode = getOutletCode();
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/shift/end`,
			data: {
				outletCode,
			},
		});
		return data?.success;
	} catch (e) {
		crashLogger.error(e);
	}
};

export const getOutletShift = async () => {
	try {
		const outletCode = getOutletCode();
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/shift/session`,
			data: {
				outletCode,
			},
		});
		return data?.session;
	} catch (e) {
		crashLogger.error(e);
	}
};

export const getHoldingStock = async () => {
	try {
		const outletCode = getOutletCode();
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/current`,
			params: {
				outletCode,
			},
		});
		if (data.holdingStocks) return data.holdingStocks;
	} catch (e) {
		crashLogger.error(e);
	}
};

export const adjustStock = async ({stockId, newCurrentQty, newRequiredQty}) => {
	try {
		const outletCode = getOutletCode();
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/current/adjust`,
			data: {
				outletCode,
				stockId,
				newCurrentQty,
				newRequiredQty,
			},
		});
		if (Object.keys(data).length === 0) throw 'Empty Response';
		return data;
	} catch (e) {
		crashLogger.error(e);
	}
};

// For Scan & Cancel
export const updateStock = async ({
	event, // scan / cancel
	scanqr,
	notes,
}) => {
	try {
		const outletCode = getOutletCode();
		const {data} = await axios({
			method: 'PUT',
			url: `${baseURL}/current/update`,
			data: {
				event,
				outletCode,
				scanqr,
				notes,
			},
		});
		if (Object.keys(data).length === 0) throw 'Empty Response';
		else return data;
	} catch (e) {
		crashLogger.error(e);
	}
};

export const findOutlet = async label => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${process.env.REACT_APP_OUTLET_API_URL}`,
			params: {
				label,
			},
		});
		if (data?.results) {
			if (data.results.length) return data.results;
		}
	} catch (e) {
		crashLogger.error(e);
	}
};

export const getQRCodeList = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/qrcode`,
		});
		return data.qrcodes;
	} catch (e) {
		crashLogger.error(e);
	}
};