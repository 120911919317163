import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Focusable} from 'library/react-js-spatial-navigation';

import clsx from 'clsx';
import {Modal, Button} from '@hangry/react-ui-kit';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSun} from '@fortawesome/pro-solid-svg-icons';

import styles from './index.module.scss';
import ScanCard from 'components/ScanCard';

import {ELEMENT_ID, STORAGE_KEYS} from 'utils/constants';
import {focusToParent, beforeClickEnter} from 'utils/utils';

import localization from 'localization';

const ScanCardOverlay = ({cards, removeCard, visible, onClose, isHistory}) => {
	const lastCardRef = useRef(null);
	const [isEmpty, setIsEmpty] = useState(true);

	const locale = isHistory
		? localization.Global.ScanCardOverlay.History
		: localization.Global.ScanCardOverlay.Default;

	const historyPlaceholderLocale = isHistory && locale.Placeholder;

	const overlayCards = isHistory
		? window.sessionStorage[STORAGE_KEYS.qrHistoryArray]
			? JSON.parse(window.sessionStorage[STORAGE_KEYS.qrHistoryArray])
			: []
		: cards;

	useEffect(() => {
		if (!visible) return;
		overlayCards.length
			? setIsEmpty(false)
			: setIsEmpty(true);
		const cardEl = document.getElementById(ELEMENT_ID.scanCard.replace('{{index}}', overlayCards.length - 1));
		if (cardEl) {
			cardEl.scrollIntoView({
				behavior: 'smooth',
				inline: 'center',
			});
		}
	}, [overlayCards, visible]);

	return (
		<Modal
			open={visible}
			className={clsx(
				styles.ScanCardOverlay,
				!isEmpty && styles['ScanCardOverlay--direction-rtl'],
			)}
		>

			{
				isEmpty
					? null
					: (
						<div className={styles.ScanCardOverlay__header}>
							{locale.title}
						</div>
					)
			}


			{/* Main Section */}
			{
				isEmpty
					? (
						<div className={styles.ScanCardOverlay__placeholder}>
							<FontAwesomeIcon
								className={styles.ScanCardOverlay__placeholder__icon}
								icon={faSun}
							/>
							<div className={styles.ScanCardOverlay__placeholder__text__container}>
								<div className={styles.ScanCardOverlay__placeholder__text__title}>
									{historyPlaceholderLocale?.title}
								</div>
								<div className={styles.ScanCardOverlay__placeholder__text__description}>
									{historyPlaceholderLocale?.description}
								</div>
							</div>
						</div>
					)
					: (
						<div className={clsx(
							styles.ScanCardOverlay__card__container,
							overlayCards.length < 4 && styles['ScanCardOverlay__card__container--few-item'],
						)}>
							<TransitionGroup component={null}>
								{
									overlayCards.map((card, i) => (
										<CSSTransition
											key={card.id}
											timeout={400}
										>
											<ScanCard
												cardData={card}
												ref={lastCardRef}
												index={i}
												removeCard={() => removeCard(card.id)}
												isHistory={isHistory}
											/>
										</CSSTransition>
									))
								}
							</TransitionGroup>
						</div>

					)
			}
			{/* End of Main Section */}

			<Focusable
				id={ELEMENT_ID.scanCardOverlayCloseBtn}
				onClickEnter={() => beforeClickEnter(onClose)}
				className={clsx(styles.ScanCardOverlay__button, 'focus-outline')}
			>
				<Button
					autoFocus
					onFocus={focusToParent}
					color='secondary'
					size='large'
					label={locale.button}
				/>
			</Focusable>
		</Modal>
	);
};

ScanCardOverlay.defaultProps = {
	cards: [],
	removeCard: () => null,
	visible: false,
	onClose: () => null,
	isHistory: false,
};

ScanCardOverlay.propTypes = {
	cards: PropTypes.array,
	removeCard: PropTypes.func,
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	isHistory: PropTypes.bool,
};

export default ScanCardOverlay;