import React, {useRef, useState, useCallback, useEffect} from 'react';

import styles from './index.module.scss';

import ConfirmationModal from 'components/Display/ConfirmationModal';
import PulseLoader from 'components/Display/PulseLoader';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/pro-solid-svg-icons';

import {Focusable} from 'library/react-js-spatial-navigation';

import {ELEMENT_ID, STORAGE_KEYS} from 'utils/constants';
import {findOutlet} from 'utils/request';
import history from 'utils/history';

const OUTLET_INPUT_MIN_CHAR = 3;
const DEBOUNCE_DELAY = 500; //? in ms

import debounce from 'lodash/debounce';

import localization from 'localization';
const locale = localization.Outlet;
const outletModalTemplate = locale.ModalTemplate;

const Outlet = () => {
	const inputRef = useRef(null);

	const [outletInput, setOutletInput] = useState('');
	const [selectedOutlet, setSelectedOutlet] = useState({});

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [outletModalContent, setOutletModalContent] = useState(outletModalTemplate);

	const [outletList, setOutletList] = useState([]);
	const [loading, setLoading] = useState(false);

	const Wrapper = props => (
		<div {...props} />
	);

	const FocusableWrapper = showConfirmationModal ? Wrapper : Focusable;

	const getOutletByLabel = async label => {
		if (label.length < 3) {
			setOutletList([]);
			setLoading(false);
		} else {
			const response = await findOutlet(label);
			if (response) setOutletList(response);
			setLoading(false);
		}
	};

	const debouncedChangeHandler = useCallback(
		debounce(getOutletByLabel, DEBOUNCE_DELAY)
		, []);

	const handleInput = value => {
		if (value.length >= 3) setLoading(true);
		setOutletInput(value);
		debouncedChangeHandler(value);
	};

	const smoothScroll = e => {
		const outletElement = e.target;
		outletElement.scrollIntoView({
			block: 'center',
		});
	};

	const handleChooseOutlet = outletData => {
		setSelectedOutlet(outletData);
	};

	const closeModal = () => {
		setSelectedOutlet({});
		setShowConfirmationModal(false);
		inputRef?.current && inputRef.current.focus();
	};

	useEffect(() => {
		if (!selectedOutlet.label) return;

		const modalContentTemp = {
			...outletModalTemplate,
			title: outletModalTemplate.title.replace('{{label}}', selectedOutlet.label),
		};

		setOutletModalContent(modalContentTemp);
		setShowConfirmationModal(true);

	}, [selectedOutlet.label]);

	useEffect(() => {
		window.sessionStorage.removeItem(STORAGE_KEYS.outletData);
	}, []);

	const confirmOutlet = () => {
		window.localStorage[STORAGE_KEYS.outletData] = JSON.stringify(selectedOutlet);
		history.push('/');
	};

	const focusToFirstOutlet = e => {
		if (e.key !== 'ArrowDown') return;
		if (loading || !outletList.length) return;
		const firstOutlet = document.getElementById(ELEMENT_ID.outletListContainer)?.firstChild?.firstChild;
		e.preventDefault();
		e.stopPropagation();
		firstOutlet?.focus();
	};

	return (
		<div className={styles.Outlet}>
			<div className={styles.Outlet__form}>

				{/* Top Section */}
				<div className={styles.Outlet__form__top__section}>
					<div className={styles.Outlet__form__title}>
						{locale.Form.title}
					</div>

					<FocusableWrapper
						onFocus={() => {
							inputRef?.current && inputRef.current.focus();
						}}
						className={styles.Outlet__form__input__wrapper}
					>
						<div className={styles.Outlet__form__icon__wrapper}>
							<FontAwesomeIcon icon={faSearch} />
						</div>
						<input
							autoFocus
							ref={inputRef}
							placeholder={locale.Form.inputPlaceholder}
							value={outletInput}
							onChange={e => handleInput(e.target.value)}
							onKeyDown={focusToFirstOutlet}
						/>
					</FocusableWrapper>
				</div>
				{/* End of Top Section */}

				{/* Body Section */}
				<div className={styles.Outlet__results__wrapper}>
					{
						outletInput.length < OUTLET_INPUT_MIN_CHAR
							? (
								<div className={styles.Outlet__results__default__text}>
									{locale.Form.resultPlaceHolder}
								</div>
							)
							: loading
								? (
									<PulseLoader className={styles.Outlet__results__loading} />
								)
								: outletList?.length
									? (
										<div
											id={ELEMENT_ID.outletListContainer}
											className={styles.Outlet__results__list}>
											{
												outletList.map(outlet => (
													<div
														key={outlet.id}
														className={styles.Outlet__results__list__item__wrapper}
													>
														<FocusableWrapper
															onFocus={smoothScroll}
															key={outlet.id}
															onClickEnter={() => handleChooseOutlet({
																label: outlet.label,
																outletCode: outlet.outletCode,
															})}
														>
															<div className={styles.Outlet__results__list__item}>
																{outlet.label}
															</div>
														</FocusableWrapper>
													</div>
												))
											}
										</div>
									)
									: (
										<div className={styles.Outlet__results__default__text}>
											{locale.Form.resultNotFound}
										</div>
									)
					}
				</div>
				{/* End of Body Section */}

			</div>
			<ConfirmationModal
				visible={showConfirmationModal}
				onClose={closeModal}
				modalContent={outletModalContent}
				confirmAction={confirmOutlet}
			/>
		</div>
	);
};

export default Outlet;