import {useEffect} from 'react';
import PropTypes from 'prop-types';

const useListener = (listener, callback = () => null, dependencies = []) => {

	useEffect(() => {
		document.addEventListener(listener, callback);
		return () => {
			document.removeEventListener(listener, callback);
		};
	}, dependencies);
};

useListener.propTypes = {
	listener: PropTypes.string,
	callback: PropTypes.func,
	dependencies: PropTypes.array,
};

export default useListener;