import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {updateStock, getQRCodeList} from 'utils/request';
import {revertFocus, showToast} from 'utils/utils';
import localization from 'localization';

import LoadingIndicator from 'components/Display/LoadingIndicator';

import useListener from 'hooks/useListener';

import {useDispatch} from 'react-redux';
import {setListOfQR} from 'store/actions/qrCodes';

import store from 'store';
import {faTimesCircle} from '@fortawesome/pro-solid-svg-icons';

window.qrCode = '';
window.isFetchingScanQR = false;

let qrWaitTimeout = null;

const QRListener = ({dependency, setScannedQR, refreshCurrentHS}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const userInteraction = e => {
		if (window.overlayActive) return true;
		if (e.code.includes('Numpad')) {
			if (e.code === 'NumpadAdd') return false;
			else return true;
		}
		if (['input', 'textarea'].includes(e.target.tagName.toLowerCase()))
			return true;
		return false;
	};

	const cleanUpQR = () => {
		!window.isFetchingScanQR && setLoading(false);
		setTimeout(() => {
			window.qrCode = '';
		}, 250);
	};

	const sumbmitQR = async () => {
		clearTimeout(qrWaitTimeout);
		if (window.overlayActive || window.isFetchingScanQR) {
			// Prevent scan action when overlay is active, excluding ScanCardOverlay
			cleanUpQR();
			return;
		}

		const {validQRs} = store.getState().qrCodes || [];
		// const receivedQR = window.qrCode;
		let receivedQR = window.qrCode;

		// TODO: remove this after training
		const mockQR = parseInt(receivedQR, 10) - 1;
		validQRs[mockQR] && (receivedQR = validQRs[mockQR].qrcode);

		// get redux state through store instead of useSelector due to data stale
		const qrExist = validQRs.some(stock => stock.qrcode === receivedQR);

		if (!qrExist) {
			showToast({
				message: localization.Global.Toast.invalidQR,
				icon: faTimesCircle,
				type: 'error',
			});
			cleanUpQR();
			return;
		}

		window.isFetchingScanQR = true;

		const payload = {
			event: 'scan',
			scanqr: receivedQR,
		};
		const result = await updateStock(payload);

		if (result) {
			const timeStampTemp = new Date(result.updatedAt);

			const timeFormater = time => {
				if (time.length === 1) return `0${time}`;
				else return time;
			};

			const addedHour = timeFormater(timeStampTemp.getHours());
			const addedMinute = timeFormater(timeStampTemp.getMinutes());

			const responsePayload = {
				id: Date.now().toString(),
				qrIdentifier: receivedQR,
				addedQty: result.changeQty,
				stockId: result.stockId,
				displayName: result.displayName,
				currentQty: result.currentQty,
				addedTime: `${addedHour}:${addedMinute}`,
				unit: result.unit,
			};

			setScannedQR(state => [...state, responsePayload]);
			refreshCurrentHS();
		}

		window.isFetchingScanQR = false;
		cleanUpQR();
	};

	const qrCodeListener = async e => {
		if (document.activeElement.tagName === 'BODY') {
			// prevent lost focus
			revertFocus();
		} else {
			if (userInteraction(e)) return;
			else {
				clearTimeout(qrWaitTimeout);

				if (e.code === 'Enter') {
					if (window.qrCode) sumbmitQR();
				} else {
					window.qrCode += e.key;
					window.qrCode.length && !loading && setLoading(true);
					qrWaitTimeout = setTimeout(() => sumbmitQR(), 2000);
				}
			}
		}
	};

	useEffect(async () => {
		const response = await getQRCodeList();
		dispatch(setListOfQR(response));
	}, []);

	useListener('keypress', qrCodeListener, dependency);

	return <LoadingIndicator visible={loading} />;
};

QRListener.defaultProps = {
	dependency: 0,
	setScannedQR: () => null,
	refreshCurrentHS: () => null,
};

QRListener.propTypes = {
	dependency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setScannedQR: PropTypes.func,
	refreshCurrentHS: PropTypes.func,
};

export default QRListener;
