import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {Focusable} from 'library/react-js-spatial-navigation';
import {Button, Modal} from '@hangry/react-ui-kit';

import styles from './index.module.scss';
import clsx from 'clsx';

import {adjustStock} from 'utils/request';
import {showToast, unitConverter} from 'utils/utils';

import localization from 'localization';
const locale = localization.Global.EditStockModal;
const toastLocale = localization.Global.Toast;

const EditStockModal = ({cardData, visible, onClose, refreshCurrentHS}) => {
	const {
		currentQty,
		requiredQty,
		stockId,
		unit,
		displayName,
	} = cardData;

	const [adjustedQty, setAdjustedQty] = useState(0);
	const [safeStock, setSafeStock] = useState(0);
	const [allowToSave, setAllowToSave] = useState(false);

	const [adjusting, setIsAdjusting] = useState(false);

	const inputRef = useRef(null);
	const safeStockRef = useRef(null);
	const secondaryButtonRef = useRef(null);

	const Wrapper = props => (
		<div {...props} />
	);

	const ButtonWrapper = allowToSave ? Focusable : Wrapper;

	const beforeClose = () => {
		onClose();
	};

	const populateState = () => {
		currentQty === 0
			? setAdjustedQty(0)
			: setAdjustedQty(currentQty);
		setSafeStock(requiredQty);
	};

	const handleAdjustStock = async () => {
		setIsAdjusting(true);
		const payload = {
			stockId,
			newCurrentQty: adjustedQty || 0,
			newRequiredQty: safeStock,
		};
		const result = await adjustStock(payload);
		setIsAdjusting(false);
		if (result) {
			showToast({
				message: toastLocale.stockAdjusted,
				icon: faCheckCircle,
			});
			refreshCurrentHS();
			beforeClose();
		}
	};

	useEffect(() => {
		visible && populateState();
	}, [visible]);

	useEffect(() => {
		const adjustedQtyTemp = adjustedQty === '' ? 0 : adjustedQty;

		if (
			currentQty === adjustedQtyTemp &&
			requiredQty === safeStock ||
			safeStock === '' ||
			adjustedQtyTemp < 0
		) {
			setAllowToSave(false);
		} else {
			setAllowToSave(true);
		}

	}, [adjustedQty, safeStock]);

	return (
		<Modal
			className={styles.EditStockModal}
			open={visible}
			onClose={beforeClose}
			onBackdropPress={beforeClose}
		>
			<div className={styles.EditStockModal__wrapper}>

				<div className={styles.EditStockModal__header}>
					{locale.title}
				</div>

				<div className={styles.EditStockModal__title}>
					{displayName}
				</div>

				{/* Input Area */}
				<div
					className={styles.EditStockModal__input__container}>
					<div>{locale.currentStock}</div>
					<div className={styles.EditStockModal__input__box}>
						<input
							ref={inputRef}
							autoFocus
							inputMode="numeric"
							className={clsx(
								styles.EditStockModal__input__field,
								String(adjustedQty)[0] === '-' && styles['EditStockModal__input__field--danger'],
							)}
							onKeyDown={e => {
								e.key === 'ArrowDown' && safeStockRef.current && safeStockRef.current.focus();
							}}
							onChange={e => {
								if (e.target.value === '') {
									setAdjustedQty('');
								} else {
									if (e.target.value.length === 1 && e.target.value === '-') {
										setAdjustedQty(e.target.value);
									} else {
										const tempValue = Number(e.target.value);
										tempValue && setAdjustedQty(tempValue);
									}
								}
							}}
							value={adjustedQty}
							placeholder={0} />
						<div className={styles.EditStockModal__input__units}>{unitConverter(unit)}</div>
					</div>
				</div>

				<Focusable
					onFocus={() => {
						safeStockRef.current && safeStockRef.current.focus();
					}}
					className={styles.EditStockModal__input__container}
				>
					<div>{locale.safeBaseline}</div>
					<div className={styles.EditStockModal__input__box}>
						<input
							ref={safeStockRef}
							className={styles.EditStockModal__input__field}
							onKeyDown={e => {
								e.key === 'ArrowUp' && inputRef.current && inputRef.current.focus();
								e.key === 'ArrowDown' && secondaryButtonRef.current && secondaryButtonRef.current.focus();
							}}
							onChange={e => {
								if (e.target.value === '') {
									setSafeStock('');
								} else {
									const tempValue = Number(e.target.value);
									tempValue && setSafeStock(tempValue);
								}
							}}
							value={safeStock}
						/>
						<div className={styles.EditStockModal__input__units}>{unitConverter(unit)}</div>
					</div>
				</Focusable>
				{/* End of Input Area */}


			</div>
			{/* Bottom Container Area */}
			<div className={styles.EditStockModal__button__container}>
				<Focusable
					onClickEnter={beforeClose}
					className={clsx(
						styles.EditStockModal__button,
						'focus-outline',
					)}
					ref={focusable => {
						if (focusable?.el) {
							secondaryButtonRef.current = focusable.el;
						}
					}}
				>
					<Button
						variant='outlined'
						size='large'
						label={locale.cancel}
					/>
				</Focusable>
				<div className={styles.EditStockModal__button__spacer} />
				<ButtonWrapper
					onClickEnter={() => {
						handleAdjustStock();
					}}
					className={clsx(
						styles.EditStockModal__button,
						'focus-outline',
					)}
				>
					<Button
						size='large'
						label={locale.save}
						isDisabled={!allowToSave || adjusting}
						isLoading={adjusting}
					/>
				</ButtonWrapper>
			</div>
			{/* End of Bottom Container Area */}
		</Modal>
	);
};

EditStockModal.defaultProps = {
	cardData: {},
	visible: false,
	onClose: () => null,
	refreshCurrentHS: () => null,
};

EditStockModal.propTypes = {
	cardData: PropTypes.object,
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	refreshCurrentHS: PropTypes.func,
};

export default EditStockModal;