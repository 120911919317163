import {ACTIONS} from 'store/actions/actionKeys';

const initialState = {
	show: false,
	message: '',
	icon: null,
	type: 'success',
};

export default function toast(state = initialState, {type, payload}) {
	switch (type) {
	case ACTIONS.SET_TOAST_MESSAGE:
		return {
			...state,
			show: true,
			message: payload.message,
			icon: payload.icon || null,
			type: payload.type,
		};
	case ACTIONS.REMOVE_TOAST:
		return {
			...state,
			show: false,
		};
	default:
		return state;
	}
}