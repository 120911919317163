import React, {useState, useEffect} from 'react';

// Global Components
import Header from 'components/Header';
import StockCard from 'components/StockCard';
import ScanCardOverlay from 'components/Display/ScanCardOverlay';
import EditStockModal from 'components/Display/EditStockModal';
import QRListener from 'components/QRListener';

import styles from './index.module.scss';

// Global Utils
import {
	showToast,
	manualFocusEl,
	handleOpenOverlay,
	handleCloseOverlay,
	beforeClickEnter,
	getOutletCode,
} from 'utils/utils';
import {ELEMENT_ID, STORAGE_KEYS} from 'utils/constants';
import history from 'utils/history';
import {getHoldingStock, updateStock, getOutletShift} from 'utils/request';

// Library
import {faCircleNotch} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import useBellSound from 'hooks/useBellSound';
import useSocket from 'hooks/useSocket';

// let notificationTimeout = null;

import localization from 'localization';
const toastLocale = localization.Global.Toast;

export default function Home() {

	// Overlay State
	const [showNewStockOverlay, setShowNewStockOverlay] = useState(false);
	const [showScanHistoryOverlay, setShowScanHistoryOverlay] = useState(false);
	const [showEditStockModal, setShowEditStockModal] = useState(false);

	const [currentHoldingStock, setCurrentHoldingStock] = useState([]); // list of every holding stock status from BE
	const [selectedCard, setSelectedCard] = useState({}); // data from singular holding stock (used for adjustment)

	const [loading, setLoading] = useState(false);

	const [scannedQR, setScannedQR] = useState([]);

	const fetchCurrentHS = async (options = {}) => {
		const {ringBellOnDeficitStock} = options;

		setLoading(true);
		const result = await getHoldingStock();
		result?.length && setCurrentHoldingStock(result);
		setLoading(false);

		const deficitStockExist = result?.some(stock => stock.warningLevel > 1);
		if (!deficitStockExist) stopSound();
		else if (ringBellOnDeficitStock && deficitStockExist) playSound();

		return result?.length;
	};

	// eslint-disable-next-line
	const socketHandler = async ({notification, event}) => {
		// clearTimeout(notificationTimeout);
		// notificationTimeout = setTimeout(async () => {
		switch (event) {
		case 'expire_stock':
		case 'expired_reminder':
		case 'current_holding_stock_update':
			await fetchCurrentHS({ringBellOnDeficitStock: true});
			break;

		case 'required_holding_stock_update':
			fetchCurrentHS({ringBellOnDeficitStock: true})
				.then(result => {
					if (!result) return;
					sessionStorage.removeItem(STORAGE_KEYS.qrHistoryArray);
					showToast({
						message: toastLocale.nextHoldingStockAlert,
						type: 'alert',
					});
				});
			break;

		default:
			break;
		}
		// }, 5000);
		// console.log(notification, event, 'incoming socket'); // TODO Remove
	};

	useSocket(`/subscribe/holding-stock/${getOutletCode()}`, socketHandler);
	const [playSound, stopSound] = useBellSound();

	const initialize = () => {
		handleCloseOverlay();
		manualFocusEl(ELEMENT_ID.historyBtn);
	};

	const beforeCloseScannedQR = () => {
		// Move scanned QR to sessionStorage when qr scan card overlay is closed
		let tempHistoryArray = [];

		if (window.sessionStorage[STORAGE_KEYS.qrHistoryArray]) {
			tempHistoryArray = JSON.parse(window.sessionStorage[STORAGE_KEYS.qrHistoryArray]);
		}

		sessionStorage.setItem(STORAGE_KEYS.qrHistoryArray, JSON.stringify(tempHistoryArray.concat(scannedQR)));
		setScannedQR([]);
	};

	const cancelScannedQR = async id => {
		const qrToCancel = scannedQR.find(card => card.id === id);
		if (!qrToCancel) return;
		const response = await updateStock({
			event: 'cancel',
			scanqr: qrToCancel.qrIdentifier,
		});
		if (!response) return;
		await fetchCurrentHS();
		setScannedQR(scannedQR.filter(card => card.id !== id));
		manualFocusEl(ELEMENT_ID.scanCardOverlayCloseBtn);
	};

	useEffect(async () => {
		const outletSession = await getOutletShift();
		if (!outletSession || !getOutletCode()) {
			// Logout
			handleCloseOverlay();
			history.push('/');
		}

		await fetchCurrentHS();
		initialize();
	}, []);

	useEffect(() => {
		!scannedQR.length && showNewStockOverlay && handleCloseOverlay(setShowNewStockOverlay(false));
		scannedQR.length && !showNewStockOverlay && handleOpenOverlay(setShowNewStockOverlay(true), null, false);
	}, [scannedQR]);

	return (
		<div className={styles.Home}>

			{/* Header Section */}
			<Header openHistory={() => {
				handleOpenOverlay(
					setShowScanHistoryOverlay(true),
					manualFocusEl(ELEMENT_ID.scanCardOverlayCloseBtn),
				);
			}} />
			{/* End of Header Section */}

			{/* Grid Section */}
			<div className={styles.Home__card__container}>
				<div className={styles.Home__card__container__grid__wrapper}>
					{
						currentHoldingStock.map((e, i) => (
							<StockCard
								key={i}
								stockData={e}
								componentId={ELEMENT_ID.stockCard.replace('{{index}}', i)}
								onClickEnter={() => {
									beforeClickEnter(() => {
										setSelectedCard(e);
										handleOpenOverlay(setShowEditStockModal(true));
									});
								}}
							/>
						))
					}
				</div>
			</div>
			{/* End of Grid Section */}

			{
				showNewStockOverlay && (
					<div className={styles.Home__scanCardOverlay__wrapper}>
						<ScanCardOverlay
							cards={scannedQR}
							visible={showNewStockOverlay}
							onClose={() => {
								beforeCloseScannedQR();
								handleCloseOverlay(setShowNewStockOverlay(false));
							}}
							removeCard={cancelScannedQR}
						/>
					</div>
				)
			}

			{
				showScanHistoryOverlay && (
					<div className={styles.Home__scanCardOverlay__wrapper}>
						<ScanCardOverlay
							visible={showScanHistoryOverlay}
							onClose={() => handleCloseOverlay(setShowScanHistoryOverlay(false))}
							isHistory
						/>
					</div>
				)
			}

			{
				loading && (
					<div className={styles.Home__loading__indicator}>
						<FontAwesomeIcon
							icon={faCircleNotch} />
					</div>
				)
			}

			<EditStockModal
				cardData={selectedCard}
				visible={showEditStockModal}
				onClose={() => handleCloseOverlay(setShowEditStockModal(false))}
				refreshCurrentHS={fetchCurrentHS}
			/>
			<QRListener
				dependency={scannedQR.length}
				setScannedQR={setScannedQR}
				refreshCurrentHS={fetchCurrentHS}
			/>
		</div>
	);
}
