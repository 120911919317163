import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import clsx from 'clsx';

function PulseLoader({className}) {
	return (
		<div
			className={clsx(styles.PulseLoader, className)}
		>
			{[1, 2, 3].map(i => (
				<div key={i} />
			))}
		</div>
	);
}

PulseLoader.defaultProps = {
	className: '',
};

PulseLoader.propTypes = {
	className: PropTypes.string,
};

export default PulseLoader;