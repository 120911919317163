import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Button} from '@hangry/react-ui-kit';

import {Focusable} from 'library/react-js-spatial-navigation';

import styles from './index.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import {focusToParent, beforeClickEnter} from 'utils/utils';

const ConfirmationModal = ({visible, onClose, modalContent, actionLoading, confirmAction}) => {
	return (
		<Modal
			className={styles.ConfirmationModal}
			open={visible}
		>
			<div className={styles.ConfirmationModal__container}>
				<FontAwesomeIcon
					className={styles.ConfirmationModal__icon}
					icon={faExclamationCircle}
				/>
				<div className={styles.ConfirmationModal__text__wrapper}>
					<div className={styles.ConfirmationModal__text__primary}>
						{modalContent?.title}
					</div>
					<div className={styles.ConfirmationModal__text__secondary}>
						{modalContent?.description}
					</div>
				</div>
				<div className={styles.ConfirmationModal__button__wrapper}>
					<Focusable
						onClickEnter={() => beforeClickEnter(onClose)}
						className={clsx(
							styles.ConfirmationModal__button,
							'focus-outline',
						)}
					>
						<Button
							autoFocus
							onFocus={focusToParent}
							size='large'
							variant='outlined'
							label={modalContent?.cancelBtn}
						/>
					</Focusable>
					<div className={styles.ConfirmationModal__button__divider} />
					<Focusable
						onClickEnter={() => !actionLoading && beforeClickEnter(confirmAction)}
						className={clsx(
							styles.ConfirmationModal__button,
							'focus-outline',
						)}
					>
						<Button
							size='large'
							isDisabled={actionLoading}
							isLoading={actionLoading}
							label={modalContent?.ctaBtn}
						/>
					</Focusable>
				</div>
			</div>
		</Modal>
	);
};

ConfirmationModal.defaultProps = {
	visible: false,
	onClose: () => null,
	modalContent: {},
	actionLoading: false,
	confirmAction: () => null,
};

ConfirmationModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	modalContent: PropTypes.object,
	actionLoading: PropTypes.bool,
	confirmAction: PropTypes.func,
};

export default ConfirmationModal;