import {combineReducers} from 'redux';

import toast from './toast';
import overlay from './overlay';
import qrCodes from './qrCodes';

export default combineReducers({
	toast,
	overlay,
	qrCodes,
});