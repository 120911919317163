import {ACTIONS} from './actionKeys';

export const setToastMessage = payload => ({
	type: ACTIONS.SET_TOAST_MESSAGE,
	payload,
});

export const removeToast = () => ({
	type: ACTIONS.REMOVE_TOAST,
});
