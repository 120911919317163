import store from 'store';
import {setToastMessage} from 'store/actions/toast';
import {addOverlay, removeOverlay} from 'store/actions/overlay';
import {ELEMENT_ID, STORAGE_KEYS} from './constants';

export const showToast = ({message, icon = null, type = 'success'}) => {
	/**
   * Supported type
   * 1. success (default)
   * 2. alert
   */
	store.dispatch(setToastMessage({message, icon, type}));
};

export const handleOpenOverlay = (action, newFocus, setWindowVar = true) => {
	store.dispatch(addOverlay());
	action?.();
	newFocus && newFocus?.();
	if (setWindowVar) window.overlayActive = true;
};

export const handleCloseOverlay = action => {
	store.dispatch(removeOverlay());
	action?.();
	revertFocus();
	window.overlayActive = false;
};

export const revertFocus = (
	elementId = window.sessionStorage[STORAGE_KEYS.lastFocused],
	delay = 250,
) => {
	setTimeout(() => {

		if (window.location.pathname === '/') { // user at Welcome Page
			const startShifBtnEl = document.getElementById(ELEMENT_ID.startShiftBtn);
			if (startShifBtnEl) {
				startShifBtnEl.focus();
			}
		} else {
			const lastFocusedEl = document.getElementById(elementId);
			if (lastFocusedEl) {
				lastFocusedEl.focus();
			} else {
				// ? if last focused element doesnt exist, fallback to first card
				const firstCard = document.getElementById(ELEMENT_ID.stockCard.replace('{{index}}', '0'));
				if (firstCard) {
					firstCard.focus();
				}

				// ? if first card doesnt exist, fallback to history button
				const historyBtn = document.getElementById(ELEMENT_ID.historyBtn);
				if (historyBtn) {
					historyBtn.focus();
				}
			}
		}

	}, delay);
};

export const manualFocusEl = (elementId, delay = 250) => {
	setTimeout(() => {
		const focusEl = document.getElementById(elementId);
		focusEl && focusEl.focus?.();
	}, delay);
};

export const focusToParent = e => {
	e?.target?.parentElement?.focus?.();
};

export const beforeClickEnter = cb => {
	/**
	 * Checkin if qrcode exist, if exist then prevent clickEnter from running
	 */
	 if (window.qrCode || window.isFetchingScanQR) return;
	 cb();
};

export const generateCardId = array => {
	// id: Date.now().toString()
	if (array.length) return array.length;
	else return 1;
};

export const getTimeZone = () => {
	// Offset from UTC +0, as we are in UTC +7, the offset will be -7 from UTC to our local timezone
	const offset = new Date().getTimezoneOffset() / 60;
	switch (offset) {
	case -7:
		return 'WIB';
	case -8:
		return 'WITA';
	case -9:
		return 'WIT';
	default:
		return 'WIB';
	}
};

export const unitConverter = unit => {
	switch (unit) {
	case 'GR':
		return 'gr';
	case 'PCS':
		return 'pcs';
	case 'POTONG':
		return 'pcs';
	default:
		return '';
	}
};

export const getOutletCode = () => {
	// re read outletCode everytime its needed to fetch to prevent data stale

	if (!localStorage[STORAGE_KEYS.outletData]) return '';
	return JSON.parse(localStorage[STORAGE_KEYS.outletData])?.outletCode;
};