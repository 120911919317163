import {ACTIONS} from 'store/actions/actionKeys';

const initialState = {
	validQRs: [],
};

export default function toast(state = initialState, {type, payload}) {
	switch (type) {
	case ACTIONS.SET_VALID_QRs:
		return {
			...state,
			validQRs: payload,
		};
	default:
		return state;
	}
}