import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle, faPlusCircle} from '@fortawesome/pro-solid-svg-icons';

import {Focusable} from 'library/react-js-spatial-navigation';

import styles from './index.module.scss';

export default function ZoomScale({threshold, onScaleChanged}) {
	const r = document.querySelector(':root');
	var rs = getComputedStyle(r);
	const currentScale = rs.getPropertyValue('--widthScale');
	const changeScale = amount => {
		const nextScale = parseFloat(currentScale) + amount;
		console.log(nextScale);
		localStorage.setItem('zoomScale', nextScale);
		const scaleLimiter = Math.min(Math.max(nextScale, 0.1), 2);
		r.style.setProperty('--widthScale', scaleLimiter);
		onScaleChanged(scaleLimiter);
	};

	useEffect(() => {
		if (localStorage.zoomScale) {
			const scaleLimiter = Math.min(Math.max(localStorage.zoomScale, 0.1), 2);
			r.style.setProperty('--widthScale', scaleLimiter);
			onScaleChanged(scaleLimiter);
		}
	}, []);

	return (
		<div className={styles.ZoomScale}>
			<Focusable
				className={clsx(styles.scale, 'focus-outline', 'hover-outline')}
				onClickEnter={() => changeScale(-threshold)}>
				<FontAwesomeIcon
					icon={faMinusCircle}
				/>
			</Focusable>
			<span>{Math.floor(currentScale * 100)}%</span>
			<Focusable
				className={clsx(styles.scale, 'focus-outline', 'hover-outline')}
				onClickEnter={() => changeScale(threshold)}>
				<FontAwesomeIcon
					icon={faPlusCircle}
				/>
			</Focusable>
		</div>
	);
}

ZoomScale.defaultProps = {
	threshold: 0.1,
	onScaleChanged: () => null,
};

ZoomScale.propTypes = {
	threshold: PropTypes.number,
	onScaleChanged: PropTypes.func,
};
