import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Client} from '@hapi/nes/lib/client';

const useSocket = (path, callback = () => null, dependencies = []) => {
	const hostURL = new URL(process.env.REACT_APP_HS_BASE_URL);
	const webSocketURL = `${hostURL.hostname}${hostURL.pathname}`;
	const client = new Client(`wss://${webSocketURL}`);

	useEffect(() => {
		client.connect({
			delay: 5000,
		});
		client.subscribe(path, callback);
		return () => {
			client.unsubscribe(path, callback);
		};
	}, dependencies);
};

useSocket.propTypes = {
	path: PropTypes.string,
	callback: PropTypes.func,
	dependencies: PropTypes.array,
};

export default useSocket;