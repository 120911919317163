import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Focusable} from 'library/react-js-spatial-navigation';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisH, faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';

import styles from './index.module.scss';
import clsx from 'clsx';

import {STORAGE_KEYS} from 'utils/constants';
import {unitConverter} from 'utils/utils';

import localization from 'localization';
const locale = localization.StockCard;

const StockCard = ({stockData, onClickEnter, componentId}) => {
	const {
		warningLevel,
		displayName,
		currentQty,
		requiredQty,
		someWillExpired,
		nextExpiredQty,
		unit,
	} = stockData;

	const overlay = useSelector(state => state.overlay);

	const Wrapper = props => (
		<div {...props} />
	);

	const StockCardWrapper = overlay.isActive ? Wrapper : Focusable;

	/**
	 * Card State Style
	 */

	const alert = styles['StockCard--alert'];
	const warn = styles['StockCard--warn'];
	const danger = styles['StockCard--danger'];

	const updateCardWarningLevel = warningLevel => {  // eslint-disable-line no-unused-vars
		/**
		 * 1. Normal (default, return nothing) *if someWillExpired false
		 * 1. Expired Soon (alert) *if someWillExpired true
		 * 2. Lack a little (warn)
		 * 3. Lack a lot (danger)
		 */

		switch (warningLevel) {
		case 1:
			if (someWillExpired) {
				return alert;
			} else {
				return null;
			}
		case 2:
			return warn;
		case 3:
			return danger;
		default:
			return null;
		}
	};

	return (
		<StockCardWrapper
			id={componentId}
			className={clsx(
				styles.StockCard,
				'focus-outline',
				updateCardWarningLevel(warningLevel),
			)}
			onFocus={e => {
				sessionStorage.setItem(STORAGE_KEYS.lastFocused, componentId);

				e.target.scrollIntoView({
					block: 'center',
				});
			}}
			onClickEnter={onClickEnter}
		>
			{/* Top Section */}
			<div className={styles.StockCard__top__section}>
				<div className={styles.StockCard__top__section__title}>
					{displayName}
				</div>
				<div
					style={{alignItems: 'flex-start'}}
					className={styles.StockCard__icon__wrapper}
				>
					<FontAwesomeIcon
						icon={faEllipsisH}
						className={styles.StockCard__top__section__icon}  />
				</div>
			</div>
			{/* End of Top Section */}

			{/* Middle Section */}
			{
				warningLevel > 1 && (currentQty < 0 || someWillExpired) && (
					<div className={styles.StockCard__middle__section}>
						<div className={styles.StockCard__middle__section__expiration__badge}>
							<div className={styles.StockCard__icon__wrapper}>
								<FontAwesomeIcon
									className={styles.StockCard__middle__section__expiration__badge__icon}
									icon={faExclamationCircle} />
							</div>
							<div className={styles.StockCard__middle__section__expiration__badge__message}>
								{
									currentQty < 0
										? locale.Badge.readjustStock
										: someWillExpired
											? `${locale.Badge.expiredMessage} ${nextExpiredQty} ${unitConverter(unit)}`
											: null
								}
							</div>
						</div>
					</div>
				)
			}
			{/* End Of Middle Section */}

			{/* Bottom Section */}
			<div className={styles.StockCard__bottom__section}>
				<div className={styles.StockCard__bottom__section__info}>
					{
						warningLevel > 1
							? locale.deficit
							: someWillExpired && locale.someWillExpired
					}
				</div>
				<div className={styles.StockCard__bottom__section__status}>
					{
						warningLevel > 1
							? `${Math.abs(currentQty - requiredQty)} ${unitConverter(unit)}`
							: someWillExpired
								? `${nextExpiredQty} ${unit.toLowerCase()}`
								: locale.everythingSafe
					}
				</div>
			</div>
			{/* End of Bottom Section */}
		</StockCardWrapper>
	);
};

StockCard.defaultProps = {
	stockDatra: {},
	onClickEnter: () => null,
	overlayVisible: false,
	componentId: '',
};

StockCard.propTypes = {
	stockData: PropTypes.object,
	onClickEnter: PropTypes.func,
	overlayVisible: PropTypes.bool,
	componentId: PropTypes.string,
};

export default StockCard;