import React, {useState, useEffect, useRef} from 'react';
import {Focusable} from 'library/react-js-spatial-navigation';
import {Button} from '@hangry/react-ui-kit';

import Header from 'components/Header';

import {ELEMENT_ID, STORAGE_KEYS} from 'utils/constants';
import history from 'utils/history';
import {revertFocus, focusToParent, showToast} from 'utils/utils';
import {startShift, getOutletShift} from 'utils/request';

import styles from './index.module.scss';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSun, faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import localization from 'localization';
const locale = localization.Welcome;
const toastLocale = localization.Global.Toast;

const Welcome = () => {
	const inputWatcherRef = useRef('');

	const [loadingLogin, setLoadingLogin] = useState(false);

	const handleLogin = async () => {
		setLoadingLogin(true);
		const result = await startShift();
		setLoadingLogin(false);
		if (result) {
			history.push('/home');
			showToast({
				message: toastLocale.shiftStarted,
				icon: faCheckCircle,
			});
		}
	};

	const handleFocus = e => {
		e.key.includes('Arrow') && revertFocus(null, 50);

		/**
		 * Listen for asterisk (*) keypress
		 * if user press asterisk (*) 5 times, then redirect to outlet page
		 */

		if (e.key === '*') {
			inputWatcherRef.current += '*';
			e.preventDefault();
			if (inputWatcherRef.current === '*****') history.push('/outlet');
		} else {
			inputWatcherRef.current = '';
		}
	};

	useEffect(() => {
		(async () => {
			window.sessionStorage.removeItem(STORAGE_KEYS.qrHistoryArray);
			if (!window.localStorage[STORAGE_KEYS.outletData]) return history.push('/outlet');
			const outletSession = await getOutletShift();
			if (outletSession) return history.push('/home');
		})();
		window.addEventListener('keydown', handleFocus);
		return () => window.removeEventListener('keydown', handleFocus);
	}, []);

	return (
		<div className={styles.Welcome}>
			<Header />
			<div className={styles.Welcome__body}>
				<FontAwesomeIcon
					className={styles.Welcome__icon}
					icon={faSun} />
				<div className={styles.Welcome__content__wrapper}>
					<div className={styles.Welcome__title}>
						{locale.title}
					</div>
					<div className={styles.Welcome__description}>
						{locale.description}
					</div>
					<Focusable
						id={ELEMENT_ID.startShiftBtn}
						onClickEnter={() => !loadingLogin && handleLogin()}
						className={clsx(styles.Welcome__button, 'focus-outline')}
					>
						<Button
							autoFocus
							onFocus={focusToParent}
							size='large'
							color='secondary'
							label={locale.button}
							isDisabled={loadingLogin}
							isLoading={loadingLogin}
						/>
					</Focusable>
				</div>
			</div>
		</div>
	);
};

export default Welcome;