import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import {Focusable} from 'library/react-js-spatial-navigation';
import {Button} from '@hangry/react-ui-kit';

import {ELEMENT_ID} from 'utils/constants';
import {beforeClickEnter, getTimeZone, unitConverter} from 'utils/utils';

import styles from './index.module.scss';

import localization from 'localization';
const locale = localization.ScanCard;

const ScanCard = ({cardData, index, removeCard, isHistory}) => {

	const {addedQty, addedTime, currentQty, displayName, unit} = cardData;

	const Wrapper = props => (
		<div {...props} />
	);

	const ScanCardWrapper = isHistory ? Focusable : Wrapper;

	const smoothScroll = e => {
		const cardElement = isHistory
			? e.target
			: e.target.parentElement;

		cardElement.scrollIntoView({
			behavior: 'smooth',
			inline: 'center',
		});
	};

	return (
		<div
			onFocus={e => isHistory && smoothScroll(e)}
			id={ELEMENT_ID.scanCard.replace('{{index}}', index)}
			className={clsx(
				styles.ScanCard,
				isHistory && styles['ScanCard--history'],
			)}
		>
			<div className={styles.ScanCard__top__section}>
				<div className={styles.ScanCard__added__amount}>+{addedQty} {unitConverter(unit)}</div>
				<ScanCardWrapper className={styles.ScanCard__name}>
					{displayName}
				</ScanCardWrapper>

				{/* Stock Detail */}
				<div className={styles.ScanCard__detail__container}>
					<div className={styles.ScanCard__detail__wrapper}>
						<div className={styles.ScanCard__detail__title}>
							{locale.addedAt}
						</div>
						<div className={styles.ScanCard__detail__value}>
							{addedTime} {getTimeZone()}
						</div>
					</div>

					<div className={styles.ScanCard__detail__wrapper}>
						<div className={styles.ScanCard__detail__title}>
							{locale.qtyAfterAdded}
						</div>
						<div className={styles.ScanCard__detail__value}>
							{currentQty} {unitConverter(unit)}
						</div>
					</div>
				</div>
			</div>
			{/* End of Stock Detail */}

			{
				!isHistory && (
					<div
						className={styles.ScanCard__bottom__section}>
						<Focusable
							onClickEnter={() => beforeClickEnter(removeCard)}
							onFocus={e => smoothScroll(e)}
							className={clsx(
								styles.ScanCard__button,
								'focus-outline',
							)}
						>
							<Button
								size='large'
								variant='outlined'
								label={locale.cancel}
							/>
						</Focusable>
					</div>
				)
			}

		</div>
	);
};

ScanCard.defaultProps = {
	cardData: {},
	index: 0,
	removeCard: () => null,
	onClose: () => null,
	isHistory: false,
};

ScanCard.propTypes = {
	cardData: PropTypes.object,
	index: PropTypes.number,
	removeCard: PropTypes.func,
	isHistory: PropTypes.bool,
};

export default ScanCard;