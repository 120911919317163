export const ELEMENT_ID = {
	stockCard: 'stock-card-{{index}}', // replace {{index}} with element index
	scanCard: 'scan-card-{{index}}', // replace {{index}} with element index
	historyBtn: 'history-btn',
	startShiftBtn: 'startShift-btn',
	endShiftBtn: 'endShift-btn',
	cancelEndShiftBtn: 'cancelEndShift-btn',
	scanCardOverlayCloseBtn: 'scanCardOverlay-close-btn',
	outletListContainer: 'outlet-list-container',
};

export const STORAGE_KEYS = {
	loggedIn: 'loggedIn', // unused To be removed
	lastFocused: 'lastFocused',
	scannedProducts: 'scannedProducts',
	outletData: 'outletData',
	qrHistoryArray: 'qrHistoryArray',
};