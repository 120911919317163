import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Focusable} from 'library/react-js-spatial-navigation';
import {Button} from '@hangry/react-ui-kit';
import {Detector} from 'react-detect-offline';

import {faCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Moment from 'react-moment';
import 'moment/locale/id';
import clsx from 'clsx';

// Utils
import {ELEMENT_ID, STORAGE_KEYS} from 'utils/constants';
import {handleOpenOverlay, handleCloseOverlay, getTimeZone, beforeClickEnter} from 'utils/utils';
import {endShift} from 'utils/request';
import history from 'utils/history';

import styles from './index.module.scss';
import ConfirmationModal from 'components/Display/ConfirmationModal';
import ZoomScale from 'components/ZoomScale';

import localization from 'localization';
const locale = localization.Global.Header;
const logoutModalContent = locale.ModalTemplate;

let outletName;

const pollingConfig = {
	url: `${process.env.REACT_APP_HS_BASE_URL}/status`,
	interval: 300000, // 5 min,
};

const Header = ({openHistory}) => {
	const [showLogoutOverlay, setShowLogoutOverlay] = useState(false);
	const [loadingLogout, setLoadingLogout] = useState(false);
	const [, setScale] = useState(1);

	const atWelcomePage = window.location.pathname === '/';

	if (localStorage[STORAGE_KEYS.outletData]) {
		outletName = JSON.parse(localStorage[STORAGE_KEYS.outletData])?.label;
	}

	const overlay = useSelector(state => state.overlay);

	const Wrapper = props => (
		<div {...props} />
	);

	const FocusableWrapper = overlay.isActive ? Wrapper : Focusable;

	const logout = async () => {
		setLoadingLogout(true);
		const result = await endShift();
		setLoadingLogout(false);
		if (result) {
			handleCloseOverlay();
			history.push('/');
		}
	};

	return (
		<React.Fragment>
			<div className={styles.Header}>
				<div className={styles.Header__time}>
					<div className={styles.Header__time__top__secion}>
						{locale.title} {
							<Moment
								locale="id"
								format='dddd, DD MMM’YY' />}
					</div>
					<div className={styles.Header__time__bottom__secion}>
						{locale.outletLocation.replace('{{location}}', outletName)}
						<Moment
							locale="id"
							format='HH:mm' /> {getTimeZone()}
					</div>
				</div>
				<div className={styles.Header__actions}>
					<Detector
						polling={pollingConfig}
						render={({online}) => (
							<div className={styles.Header__connection__indicator}>
								<FontAwesomeIcon
									icon={faCircle}
									className={clsx(
										styles.Header__connection__indicator__icon,
										!online && styles['Header__connection__indicator__icon--danger'],
									)}
								/>
								<div className={styles.Header__connection__indicator__text}>
									{
										online ? 'Online' : 'Offline'
									}
								</div>
							</div>
						)}
					/>
					{
						!atWelcomePage && (
							<>
								<ZoomScale
									threshold={0.05}
									onScaleChanged={setScale} />
								<FocusableWrapper
									onClickEnter={() => beforeClickEnter(openHistory)}
									id={ELEMENT_ID.historyBtn}
									onFocus={() => {
										sessionStorage.setItem(STORAGE_KEYS.lastFocused, ELEMENT_ID.historyBtn);
									}}
									className={clsx(
										styles.Header__actions__btn,
										'focus-outline',
									)}>
									<Button
										color='secondary'
										size='large'
										label={locale.history}
									/>
								</FocusableWrapper>
								<FocusableWrapper
									id={ELEMENT_ID.endShiftBtn}
									onFocus={() => {
										sessionStorage.setItem(STORAGE_KEYS.lastFocused, ELEMENT_ID.endShiftBtn);
									}}
									onClickEnter={() => {
										beforeClickEnter(() => handleOpenOverlay(setShowLogoutOverlay(true)));
									}}
									className={clsx(
										styles.Header__actions__btn,
										'focus-outline',
									)}>
									<Button
										color='secondary'
										size='large'
										label={locale.endShift}
										variant='outlined'
									/>
								</FocusableWrapper>
							</>
						)
					}
				</div>
			</div>
			{
				!atWelcomePage && (
					<ConfirmationModal
						visible={showLogoutOverlay}
						onClose={() => {
							handleCloseOverlay();
							setShowLogoutOverlay(false);
						}}
						modalContent={logoutModalContent}
						actionLoading={loadingLogout}
						confirmAction={logout}
					/>
				)
			}
		</React.Fragment>
	);
};

Header.defaultProps = {
	openHistory: () => null,
};

Header.propTypes = {
	openHistory: PropTypes.func,
};

export default Header;