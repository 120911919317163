import {useRef, useEffect} from 'react';
import BellMp3 from 'assets/sounds/bell.mp3';

const Bell = new Audio(BellMp3);
const bellDuration = 45000; //in ms

let loop;
let loopWatcher;

const useBellSound = () => {
	const bellFlagging = useRef(false);

	const play = () => {
		bellFlagging.current && Bell.play();
	};

	const stop = () => {
		bellFlagging.current = false;
		clearInterval(loop);
	};

	const handlePlaySound = () => {
		bellFlagging.current = true;
		play();
		loop = setInterval(() => {
			play();
		}, 2500);

		setTimeout(() => {
			stop();
		}, bellDuration);
	};

	const handleInteraction = () => {
		bellFlagging.current && stop();
	};

	useEffect(() => {
		document.addEventListener('keydown', handleInteraction);
		return () => {
			document.removeEventListener('keydown', handleInteraction);
			clearInterval(loop);
			clearTimeout(loopWatcher);
		};
	}, []);

	return [handlePlaySound, stop];
};

export default useBellSound;
