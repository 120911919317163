import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import PulseLoader from 'components/Display/PulseLoader';
import styles from './index.module.scss';

function LoadingIndicator({visible}) {
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		visible
			? setShowLoader(true)
			: showLoader && setShowLoader(false);
	}, [visible]);

	return (
		showLoader && (
			<div className={styles.LoadingIndicator}>
				<div className={styles.overlay}>
					<PulseLoader />
				</div>
			</div>
		)
	);
}

LoadingIndicator.defaultProps = {
	visible: false,
	renderDone: true,
};

LoadingIndicator.propTypes = {
	visible: PropTypes.bool,
	renderDone: PropTypes.bool,
};

export default LoadingIndicator;