import {ACTIONS} from 'store/actions/actionKeys';

const initialState = {
	isActive: false,
};

export default function toast(state = initialState, {type}) {
	switch (type) {
	case ACTIONS.ADD_OVERLAY:
		return {
			...state,
			isActive: true,
		};
	case ACTIONS.REMOVE_OVERLAY:
		return {
			...state,
			isActive: false,
		};
	default:
		return state;
	}
}