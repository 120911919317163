import React from 'react';
import {
	Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';

import Welcome from 'views/Welcome';
import Home from 'views/Home';
import Outlet from 'views/Outlet';

import Toast from 'components/Toast';

import './App.scss';
import '@hangry/react-ui-kit/dist/index.css';

import history from 'utils/history';

import SpatialNavigation from 'library/react-js-spatial-navigation';

import Moment from 'react-moment';
Moment.startPooledTimer(1000);

export default function App() {
	return (
		<div>
			<Router history={history}>
				<SpatialNavigation>
					<Toast />
					<Switch>
						{/* Available path */}
						<Route path="/home">
							<Home />
						</Route>
						<Route path="/outlet">
							<Outlet />
						</Route>
						<Route
							exact
							path="/">
							<Welcome />
						</Route>

						{/* Invalid path (redirect to home) */}
						<Redirect to="/" />
					</Switch>
				</SpatialNavigation>
			</Router>
		</div>
	);
}
